import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Element } from "react-scroll";
import "./global.css";

import LandingPage from "./components/pages/landing_page";
import DataRequest from "./components/pages/data_request";
import NotFound from "./components/pages/not_found";

import { TranslationProvider } from "./TranslationContext";
import sk from "./locales/sk.json";
import en from "./locales/en.json";
import cz from "./locales/cz.json";
import de from "./locales/de.json";

const languageMap: { [key: string]: typeof sk } = {
  SK: sk,
  CZ: cz,
  DE: de,
  EN: en,
  default: sk,
};

const getLanguage = (): typeof sk => {
  // Check for language cookie first
  const langCookie = Cookies.get("lang");
  if (langCookie && languageMap[langCookie]) {
    return languageMap[langCookie];
  }

  return languageMap["default"];
};

function InnerApp() {
  const location = useLocation();

  const [translations, setTranslations] = useState(sk);

  useEffect(() => {
    const selectedLanguage = getLanguage();
    setTranslations(selectedLanguage);
  }, []);

  //Toto tu musi byť inak nebude sticky navbar
  const [isSelectOpen] = useState(false);

  return (
    <TranslationProvider translations={translations}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/data-request" element={<DataRequest />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </TranslationProvider>
  );
}

function App() {
  return (
    <Router>
      <InnerApp />
    </Router>
  );
}

export default App;
