import PageHeader from "../ui/custom/page_header";
import { Link as RouterLink } from "react-router-dom";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import logo from "../../assets/svg/nexprim.svg";
import { Button } from "../ui/button";
import React, { useState, useEffect } from "react";
import { useTranslation } from "../../TranslationContext";

import email_sent from "../../assets/svg/email_sent.svg";
import email_error from "../../assets/svg/email_error.svg";

const Contact = () => {
  const [nameAndSurname, setNameAndSurname] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");

  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const translations = useTranslation();

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    console.log("submit");

    // Required fields
    const requiredFields = [
      { name: "nameAndSurname", value: nameAndSurname },
      { name: "email", value: email },
      { name: "note", value: note },
    ];

    // Check if all required fields are filled
    const unfilledFields = requiredFields.filter((field) => !field.value);

    if (unfilledFields.length > 0) {
      alert(
        `Please fill the following fields: ${unfilledFields
          .map((field) => field.name)
          .join(", ")}`
      );
      return;
    }

    setIsWaitingForResponse(true);
    window.scrollTo(0, 100);

    // If everything is correct, create JSON and print it to console
    const payload = {
      note,
      nameAndSurname,
      email,
    };

    console.log(payload);

    const url =
      "https://europe-central2-nexprim-5569f.cloudfunctions.net/data-request";

    console.log(JSON.stringify(payload));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        setIsError(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // or response.text() if the response is not a JSON object
      if (data.message === "Email sent") {
        setIsEmailSent(true);
      }
      console.log("Response data:", data);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsError(true);
    } finally {
      // Set isLoading to false when the API request ends
      // setIsWaitingForResponse(false);
    }
  };

  useEffect(() => {
    if (isWaitingForResponse || isEmailSent || isError) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Allow scrolling
      document.body.style.overflow = "auto";
    }
  }, [isWaitingForResponse, isEmailSent, isError]);

  return (
    <div className="font-outfit flex items-center justify-center bg-white max-w-full w-full">
      {isWaitingForResponse && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-28 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.dataRequest.waitingForResponseTitle}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  <span>
                    {translations.dataRequest.waitingForResponseSubtitleOne}
                  </span>
                  <span className="font-medium text-gradient">
                    {translations.dataRequest.waitingForResponseSubtitleTwo}
                  </span>
                </div>
              </div>
              <span className="loader-38"> </span>
            </div>
          </div>
        </div>
      )}
      {isEmailSent && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-start gap-28 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.dataRequest.emailSentTitle}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  {translations.dataRequest.emailSentSubitle}
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_sent} />
              <RouterLink to="/">
                <Button
                  variant="primary"
                  size="default"
                  className="uppercase"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsEmailSent(false);
                    setIsWaitingForResponse(false);
                  }}
                >
                  {translations.dataRequest.emailSentButton}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      )}
      {isError && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-20 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.dataRequest.errorTitle}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  {translations.dataRequest.errorSubtitle}
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_error} />
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex flex-row items-end justify-center gap-6">
                  <RouterLink
                    to="/data_request"
                    style={{ display: "inline-block", padding: 0, margin: 0 }}
                  >
                    <Button
                      variant="primary"
                      size="default"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setIsError(false);
                        setIsEmailSent(false);
                        setIsWaitingForResponse(false);
                      }}
                    >
                      {translations.dataRequest.errorButtonOne}
                    </Button>
                  </RouterLink>
                  <Button
                    variant="default"
                    size="default_stretch"
                    type="submit"
                    onClick={() => {
                      setIsError(false);
                      setIsEmailSent(false);
                      handleSubmit();
                    }}
                  >
                    {translations.dataRequest.errorButtonTwo}
                  </Button>
                </div>
                <div className="relative max-w-[500px] text-base sm:text-sm leading-[150%] text-slate-600">
                  {translations.dataRequest.errorDescription}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <div
          style={{ visibility: isWaitingForResponse ? "hidden" : "visible" }}
        >
          <div className="self-stretch flex flex-col items-start justify-center p-10 px-24 gap-20 sm:gap-10 text-4xl md:text-3xl text-livid-900 sm:px-12 xs:px-6 xs:py-8">
            <RouterLink to="/" className="min-w-[146px]">
              <img src={logo} alt="Logo" style={{ float: "left" }} />
            </RouterLink>
          </div>
          <div className="bg-white h-fit w-full self-stretch flex flex-col">
            <PageHeader
              title={translations.dataRequest.pageHeaderTitle}
              subtitle={translations.dataRequest.pageHeaderSubtitle}
              background="default"
              maxWidth="default"
            />

            <div className="self-stretch flex flex-col items-center justify-center pt-10 px-24 gap-20 sm:gap-10 text-4xl md:text-3xl text-livid-900 sm:px-12 xs:px-6 xs:py-8 w-full">
              <div className="max-w-[1200px] w-full flex flex-col items-start justify-center text-left text-sm text-slate-800">
                <form
                  className="max-w-[768px] w-full"
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    // Assert e.target as an HTMLElement to access tagName
                    const target = e.target as HTMLElement;
                    if (e.key === "Enter" && target.tagName !== "TEXTAREA") {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="w-full flex flex-col">
                    <div className="w-full flex flex-row flex-wrap items-center justify-start gap-8 max-w-[768px]">
                      <div className="w-full self-stretch flex flex-row items-center justify-center text-sm font-text-sm-font-semibold">
                        <div className="w-full self-stretch flex flex-row items-center justify-center gap-3 text-slate-500">
                          <div className="w-full  self-stretch flex flex-col items-center justify-center gap-[12px]">
                            <div className="w-full self-stretch flex flex-row items-center justify-center gap-[12px] align-bottom sm:flex-col">
                              <div className="self-stretch flex-1">
                                <Input
                                  type="text"
                                  label={translations.dataRequest.nameLabel}
                                  warningText={
                                    translations.dataRequest.nameWarning
                                  }
                                  validationRule={/^[\p{L}'-]+ [\p{L}'-]+$/u}
                                  onValueChange={setNameAndSurname}
                                  autoComplete="name"
                                />
                              </div>
                              <div className="self-stretch flex-1">
                                <Input
                                  type="email"
                                  label={translations.dataRequest.emailLabel}
                                  warningText={
                                    translations.dataRequest.emailWarning
                                  }
                                  validationRule={
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                  }
                                  onValueChange={setEmail}
                                  autoComplete="email"
                                />
                              </div>
                            </div>

                            <div className="flex justify-center items-center w-full h-60 bg-white">
                              <Textarea
                                label={translations.dataRequest.noteLabel}
                                placeholder={
                                  translations.dataRequest.notePlaceholder
                                }
                                onChange={(e) => setNote(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch w-full flex flex-col items-start justify-center text-sm text-red-400 font-text-sm-font-semibold gap-3">
                        <Button
                          variant="default"
                          size="default_stretch"
                          type="submit"
                        >
                          {translations.dataRequest.button}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Contact;
